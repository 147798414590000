import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import './reroll.scss';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { Card } from 'react-bootstrap';
import { AshTrace } from '../../../modules/ash/components/ash-trace';
import ScrollspyNav from 'react-scrollspy-nav';

const AshGuidesTeam: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide ash-reroll'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Building Guide</li>
      </ul>
      <div className="page-header ">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_teams.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Team Building Guide</h1>
          <h2>
            A team building guide for Ash Echoes that will help you build strong
            teams!
          </h2>
          <p>
            Last updated: <strong>11/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                In this guide you can find various teams that you can build in
                Ash Echoes - not only the core composition, but also replacement
                characters and the Memory Traces you should use.
              </p>
              <SectionHeader title="Corrosion Team" />
              <h6>Team Introduction</h6>
              <p>
                The Corrosion team boasts high, map-wide damage on a timer
                dictated by how many Corrosion Accumulation sources the team
                has, and functions outside the elemental reaction system, not
                interacting with elemental zones, and therefore not requiring
                elemental zone sources. This team can be run efficiently with as
                few as 2 Corrosion Accumulation sources, or even just one if at
                high enough investment.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="TRM">TRM:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader: <strong>Caroline</strong> (Switch to{' '}
                  <strong>Leilani</strong> later)
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Corrosion`}>
                <div className="team-header">
                  <p>Corrosion Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="caroline"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="gina"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Lorelle</strong> is used as Tank as she increases the
                  team's survivability and damage potential. T
                </li>
                <li>
                  <strong>Caroline</strong>is used as Sub-DPS and secondary
                  source of healing.
                </li>
                <li>
                  <strong>Gina</strong> is used for Burst Damage and her
                  Anti-Projectile capabilities, and provides Corrosion
                  Accumulation just like the previous two Echomancers.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="luke"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Luke is a versatile Summoner who
                  specializes in Barriers and Turrets, and is a good flex
                  character to bring to any stages where Projectiles may
                  threaten the Terminal.
                </li>
                <li>
                  <AshCharacter
                    slug="aceo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Aceo can be used for a more
                  control-oriented DPS option that still contributes with
                  Corrosion Accumulation, with some area denial at the expense
                  of Anti-Air capabilities.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A healer option with defensive
                  buffs and suited for long battles, can also contribute with a
                  bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="bellia"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Bellia is a source of healing
                  over time with some crowd control on top thanks to her slowing
                  attacks. Bring her if you don't have any of the previous
                  options.
                </li>
                <li>
                  <AshCharacter
                    slug="bubbles"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A cheap source of healing, if you
                  have had no luck pulling higher rarity supports, a high dupe
                  bubbles will perform admirably well, especially in keeping
                  your tank alive.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <p>
                Leader: <strong>Lorelle</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Hidden Blade" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />{' '}
                  or <AshTrace name="Nightmare" mode="data" onProfile /> (if
                  with Longqing)
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile /> or{' '}
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <p>
                Leader: <strong>Caroline or other</strong>
              </p>
              <ul>
                <li>
                  Switch{' '}
                  <AshTrace name="Double Attraction" mode="data" onProfile /> or{' '}
                  <AshTrace name="Karma" mode="data" onProfile /> to{' '}
                  <AshTrace name="Illusion of Flowers" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile /> (in
                  place of "Shadows of the Alley")
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="The Sun Never Sets" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Electrocution Team" />
              <h6>Team Introduction</h6>
              <p>
                The Eletrocution team uses Water Zones to continuously stunlock
                enemies through the application of Lightning damage, which
                propagates across the elemental surfaces, dealing high damage
                thanks to the team's Elemental Reaction DMG Boost. Needs at
                least one source of Water Zones and one source of Lightning
                Damage.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong> (prioritize{' '}
                    <strong className="VIT">VIT</strong> if running Yuqi)
                  </li>
                </ul>
                <li>
                  Leader:{' '}
                  <strong>Freda / Longqing / Furay & Furney /Sambheka</strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Electrocution Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="yuqi"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="furay-and-furney"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role water">Water Zone</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lydia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Yuqi</strong> is used as Tank who can soak up enemies,
                  group up enemies and provide some Lightning damage. He is also
                  used to fulfill the 2 Lightning characters requirement for
                  Remaining Friends Trace, and triggers My Vision is Clear! as
                  well.
                </li>
                <li>
                  <strong>Furay & Furney</strong> are used for consistent Water
                  Zone Generation.
                </li>
                <li>
                  <strong>Lydia</strong> is the main DPS carry of the team who
                  is great at dealing with groups of enemies and has Barrier
                  Penetration.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Water Zone]</strong> - Helps keep the team safe by
                  pushing enemies away and clearing out Poison Mist. Can also
                  replace Furay & Furnay as a cheaper option to Water Zone
                  creation or boost the creation and duration of such zones by
                  changing the Weather with her Seed Skill.
                </li>
                <li>
                  <AshCharacter
                    slug="luke"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Luke is a versatile Summoner who
                  specializes in Barriers and Turrets, and is a good flex
                  character to bring to any stages where Projectiles may
                  threaten the Terminal.
                </li>
                <li>
                  <AshCharacter
                    slug="pris"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Alternative Lightning Damage source
                  that benefits from having Freda in the team, while also
                  offering minion synergy support and access to combo active
                  skill. Lower damage output than Lydia.
                </li>
                <li>
                  <AshCharacter
                    slug="ms-lew"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Budget Lightning Damage source.
                  Surprisingly good damage for a 4* damage dealer. Her ultimate
                  can outdamage higher rarity single dupe carries when maxed
                  out.
                </li>
                <li>
                  <AshCharacter
                    slug="hassel"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - High damage, but does not trigger
                  electrocution as often as we would like due to his dual
                  element kit. Still, if you happen to have a highly invested
                  Hassel you can bring him.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A healer option with defensive
                  buffs and suited for long battles, can also contribute with a
                  bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="bubbles"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A cheap source of healing, if you
                  have had no luck pulling higher rarity supports, a high dupe
                  bubbles will perform admirably well, especially in keeping
                  your tank alive.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace name="My Vision Is Clear!" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />{' '}
                  or <AshTrace name="Separation" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Remaining Friends" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />{' '}
                  or <AshTrace name="Company" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Deadline" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Managing Marks" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Lectures On Arcane Theories"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Freeze Team" />
              <h6>Team Introduction</h6>
              <p>
                The Freeze team uses Ice Echomancers to keep enemies
                continuously Frozen, with a chance to instakill enemies if you
                stack enough of it with [Frostbreak]. Water surfaces make the
                team more consistent.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader: <strong>Ivana</strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Freeze Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="sambheka"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role water">Water Zone</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="ivana"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Sambheka</strong> is ran for Water Zone generation,
                  keeping enemies Frozen when her ultimate is up, and pushing
                  enemies away when it is not. Her Lightning also triggers
                  [Electrolyzed] from time to time which reduces enemy damage
                  dealt, which makes for great role compression as we don't need
                  to bring two different Echomancers for Water Generation and
                  Lightning Damage.
                </li>
                <li>
                  <strong>Ivana</strong> is the main dps carry, dealing the bulk
                  of the team's damage while also freezing enemies and applying
                  her powerful [Everfrost] debuff.
                </li>
                <li>
                  <strong>Begonia</strong> is the Sub-DPS who also contributes
                  with Attack Speed buffs to help keep enemies Frozen.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="furay-and-furney"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Water Zone]</strong> - Bring these instead of
                  Sambheka if you want more Water Zone uptime at the expense of
                  utility potential.
                </li>
                <li>
                  <AshCharacter
                    slug="luke"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Luke is a versatile Summoner who
                  specializes in Barriers and Turrets, and is a good flex
                  character to bring to any stages where Projectiles may
                  threaten the Terminal.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A healer option with defensive
                  buffs and suited for long battles, can also contribute with a
                  bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="bellia"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Bellia is a source of healing
                  over time with some crowd control on top thanks to her slowing
                  attacks. She also contributes to Freeze uptime in this team.
                </li>
                <li>
                  <AshCharacter
                    slug="bubbles"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A cheap source of healing, if you
                  have had no luck pulling higher rarity supports, a high dupe
                  bubbles will perform admirably well, especially in keeping
                  your tank alive.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Hello, Visier" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace
                    name="Lectures On Arcane Theories"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Strongest Summoner" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Summoner (Physical) Team" />
              <h6>Team Introduction</h6>
              <p>
                The Summoner Team is all about overwhelming the enemy with a
                flood of individually weak summons, that get much more
                threatening when a dozen or two of them can be active at the
                same time.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader: <strong>Cen Ying</strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Summoner Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="luke"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Lorelle</strong> being the strongest Tank on launch,
                  is the default choice for most teams that don’t require their
                  own Tank to fit in the team’s synergy. The rest of the team is
                  naturally squishy, which further increases Lorelle’s appeal.
                </li>
                <li>
                  <strong>Cen Ying</strong> is the main DPS of the team,
                  offering both damage and burst.
                </li>
                <li>
                  <strong>Luke</strong> is an amazing Support/Sub-DPS, his
                  summons also work as Barriers for extra synergy, and his
                  Ultimate covers a long area and counters all Projectiles in
                  its range.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - doesn’t synergize all that well with
                  the team, but her ability to keep enemies far away is welcome,
                  on top of countering bad Field conditions like Poison and
                  such.
                </li>
                <li>
                  <AshCharacter
                    slug="tong"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Tong is perfect if you want to abuse
                  a Burst window or need Projectile Interception
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output.
                </li>
                <li>
                  <AshCharacter
                    slug="tian-ruo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Tian Ruo strikes again! If you need
                  more Barriers, or simply want to keep Luke’s Skill for damage
                  and not defense Tian Ruo is a solid, cheap option to act as a
                  Tank.
                </li>
                <li>
                  <AshCharacter
                    slug="gauya"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Gauya offers a Summon and frequent
                  slows. He won’t offer much damage wise, but his kit can still
                  fill a niche role.
                </li>
                <li>
                  <AshCharacter
                    slug="sephonis"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Sephonis brings quite a bit of CC and
                  participates in the Summon synergy. Not the best, but CC can
                  be a lifesaver and if you enjoy playing her she’ll do well
                  here.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Zodivin is a healer option with
                  defensive buffs and suited for long battles, can also
                  contribute with a bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - If it’s not broken, don’t fix it!
                  Thanks to her versatile kit, Su Xiao can stand in as the
                  Support of the team until you get more options.
                </li>
                <li>
                  <AshCharacter
                    slug="shu"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank/CC]</strong> - Shu is there mostly for AoE Gauge
                  Breaking and Disruption with small Stuns. Don’t rely on him
                  too much to tank, that’s best done with Lorelle
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Bad Soil Bloom" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile /> (if with
                  Longqing or Tian Ruo) or{' '}
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                  (Replaces “Bad Soil Bloom”)
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Racing Night" mode="data" onProfile /> (if
                  using Luke)
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Wish In The Rain" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Pure Fire Team" />
              <h6>Team Introduction</h6>
              <p>
                Exploiting the Damage over Time of the Fire Zone and the
                powerful synergies of the Fire roster, the Pure Fire Team excels
                at fighting Ground enemies, no matter their size or their
                numbers.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader: <strong>Cyros</strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Pure Fire Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="roar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Mo Hongxiu</strong> is the Tank, and the secondary
                  Fire Zone creator in the main team. She's also gonna be the
                  second Fire Echomancer in your active team to enable Inspire
                  skills that would require two Fire Echomancers present.
                </li>
                <li>
                  <strong>Cyros</strong> is the primary Fire Zone creator thanks
                  to his transformed Active Skill and he also doubles as a very
                  powerful damage dealer.
                </li>
                <li>
                  <strong>Roar</strong> is your second DPS, armed with powerful
                  Basic Attacks that get stronger as the fight goes on, and a
                  small Fire Zone tied to her Ultimate.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs
                  that can take your Burst to another level.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Sambheka doesn’t synergize all that
                  well with the team, but her ability to keep enemies far away
                  is welcome, on top of countering bad Field conditions like
                  poison and such. Like Longqing, she can trigger the
                  [Vaporized] reaction.
                </li>
                <li>
                  <AshCharacter
                    slug="luke"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - In the Fire Team, Luke's main draw is
                  his Barrier spamming ability and his Projectile Destruction.
                  His personal DPS isn't all that great, but since we have some
                  Summon Synergy in our Traces he's alright.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output. Can also trigger the [Vaporized]
                  reaction to turn the offensive Fire Zone into a defensive
                  Mist.
                </li>
                <li>
                  <AshCharacter
                    slug="tian-ruo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Tian Ruo makes his appearance once
                  more! Small, spammable Barrier and a team Heal are more than
                  enough to earn him a spot early on.
                </li>
                <li>
                  <AshCharacter
                    slug="xiangling"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Xiangling is another Fire Zone creator
                  thanks to her transformed Skills, as well as a great Disruptor
                  with strong Push and Pull capabilities.
                </li>
                <li>
                  <AshCharacter
                    slug="reid"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Fire Zone]</strong> - Reid has very good Fire Zone
                  creation and can be switched in and out for more consistency.
                  His damage is lacking compared to the rest of the DPS, and his
                  Zones cover tiny areas making them somewhat impractical for
                  AoE.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Zodivin is a healer option with
                  defensive buffs and suited for long battles, can also
                  contribute with a bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Thanks to her versatile kit, Su
                  Xiao can stand in as the Support of the team until you get
                  more options. On top of that, her permanent Crit rate buff
                  synergizes extremely well with the Fire Team.
                </li>
                <li>
                  <AshCharacter
                    slug="infa"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Infa is a correct Tank and she can
                  potentially replace Mo Hongxiu as a Fire Tank, however she
                  struggles at high difficulty.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace name="Into a Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile /> or
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                  (Replaces “Shadows Of The Alley”)
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Wish In The Rain" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Explosion Team" />
              <h6>Team Introduction</h6>
              <p>
                Excellent Burst and great synergies are what define the
                Explosion Team. Ground enemies won't survive long but be aware
                that Fire Zones do not affect Airborne Enemies.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader: <strong>Cyros</strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Explosion Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lydia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Mo Hongxiu</strong> is the Tank, and the secondary
                  Fire Zone creator in the main team. She's also gonna be the
                  second Fire Echomancer in your active team to enable Inspire
                  skills that would require two Fire Echomancers present.
                </li>
                <li>
                  <strong>Cyros</strong> is the primary Fire Zone creator thanks
                  to his transformed Active Skill and he also doubles as a very
                  powerful damage dealer.
                </li>
                <li>
                  <strong>Lydia</strong> is your Lightning carry, with access to
                  powerful Lightning basic attacks and Auto skill, along with
                  devastating nukes once she's had the time to ramp up.
                </li>
                <li>
                  <strong>Freda</strong> provides consistent healing and buffs
                  that can take your Burst to another level.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Sambheka's ability to keep enemies far
                  away is welcome, on top of countering bad Field conditions
                  like poison and such. Like Longqing, she can trigger the
                  [Vaporized] reaction. To top it off, she can turn your
                  Explosion team into a weaker version of the Electroconduct
                  team if you need bigger AoEs.
                </li>
                <li>
                  <AshCharacter
                    slug="luke"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - In the Explosion Team, Luke's main
                  draw is his Barrier spamming ability and his Projectile
                  Destruction. His personal DPS isn't all that great as we have
                  little to no Summon synergy.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output. Can also trigger the [Vaporized]
                  reaction to turn the offensive Fire Zone into a defensive
                  Mist.
                </li>
                <li>
                  <AshCharacter
                    slug="tian-ruo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Tian Ruo makes his appearance once
                  more! Small, spammable Barrier and a team Heal are more than
                  enough to earn him a spot early on.
                </li>
                <li>
                  <AshCharacter
                    slug="xiangling"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Xiangling is another Fire Zone creator
                  thanks to her transformed Skills, as well as a great Disruptor
                  with strong Push and Pull capabilities.
                </li>
                <li>
                  <AshCharacter
                    slug="reid"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Fire Zone]</strong> - Reid has very good Fire Zone
                  creation and can be switched in and out for more consistency.
                  His damage is lacking compared to the rest of the DPS, and his
                  Zones cover tiny areas making them somewhat impractical for
                  AoE.
                </li>
                <li>
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Zodivin is a healer option with
                  defensive buffs and suited for long battles, can also
                  contribute with a bit of DPS.
                </li>
                <li>
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Thanks to her versatile kit, Su
                  Xiao can stand in as the Support of the team until you get
                  more options. On top of that, her permanent Crit rate buff
                  synergizes extremely well with the Fire Team.
                </li>
                <li>
                  <AshCharacter
                    slug="hassel"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Hassel is a powerful damage dealer,
                  but having a mix of Physical and Lightning damage doesn't help
                  his case. If you manage to time his Burst window properly with
                  the rest of your team however, he can make quick work of just
                  about any foe.
                </li>
                <li>
                  <AshCharacter
                    slug="pris"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Pris offers almost exclusively AoE
                  output, and is more focused on wider coverage. Her skills
                  apply many instances of damage but the multipliers are fairly
                  low, so avoid playing her when facing the more durable foes
                </li>
                <li>
                  <AshCharacter
                    slug="ms-lew"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Ms. Lew possesses high Single target
                  damage as well as good AoE capabilities when targeting smaller
                  packs of enemies, making her a great pick in most situations.
                  She can also be a Leader when playing two Lightning
                  Echomancers in main team
                </li>
                <li>
                  <AshCharacter
                    slug="mars"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Mars is good at both Anti-Air and
                  reducing Elemental Resistance. As a Striker, he also has
                  naturally high Block Gauge Breaking.
                </li>
                <li>
                  <AshCharacter
                    slug="yuqi"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Yuqi is there to either replace Mo
                  Hongxiu if she's taken out or serve as a secondary Tank in
                  case you need to focus on defense. He's capable of easily
                  enabling Explosion/Elemental reaction based skills from Traces
                  for example.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace name="Elegy At Dusk" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Deadline" mode="data" onProfile /> or{' '}
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lightchaser" mode="data" onProfile /> or
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Blows? Bonds?" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Wind (Pre-Scarlett) Team" />
              <h6>Team Introduction</h6>
              <p>
                With high ATK Speed, Anti-Air and great CC options, the Wind
                team sets itself apart as a more specialized Team at launch,
                with Echomancers easily splashable into most other Teams.
              </p>
              <h6>Stats and Leader</h6>
              <ul>
                <li>Engraving Stats:</li>
                <ul>
                  <li>
                    <strong className="ATK">ATK:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    <strong className="MST">MST:</strong> <strong>1400</strong>
                  </li>
                  <li>
                    Other stats: <strong>800</strong>
                  </li>
                </ul>
                <li>
                  Leader:{' '}
                  <strong>
                    Shiratori Azusa / Zodivin Azure (if using 'Blood Ruvia -
                    Secret')
                  </strong>
                </li>
              </ul>
              <h6>Team Composition</h6>
              <div className={`character-teams box Lightning`}>
                <div className="team-header">
                  <p>Wind Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="shiratori-azusa"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="felicio"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="zodivin-azure"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <strong>Lorelle</strong> - When you’re not sure which Tank to
                  bring, you can count on Lorelle. Very powerful defensive
                  options, and an offensive mode to capitalize on Wind’s
                  tendency to lean towards DPS, Lorelle fits the team very well.
                </li>
                <li>
                  <strong>Azusa</strong> is the team's Carry at launch, with
                  Ranged and Melee damage as well as DPS/Burst options in her
                  kit.
                </li>
                <li>
                  <strong>Felicio</strong> is one of the better support for DPS
                  teams and fits the early Wind synergy very well. His massive
                  ATK Speed buffs and his control of the Air makes him a great
                  fit for most situations.
                </li>
                <li>
                  <strong>Zodivin Azure</strong> brings defensive buffs and
                  powerful Heals to ensure the team’s safety, as Wind
                  Echomancers tend to be on the fragile side.
                </li>
              </ul>
              <h6>Alternatives and Extra Slot Candidates</h6>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Sambheka is yet another control tool
                  that works well within the Wind team, bringing more ways to
                  keep the enemies at bay a bit like Lanlan does, except that
                  she trades some of that CC for more weather manipulation.
                </li>
                <li>
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - A more defensive option for
                  healing, with access to powerful barriers, at the expense of
                  overall offensive output. Can also trigger the [Vaporized]
                  reaction to turn the offensive Fire Zone into a defensive
                  Mist.
                </li>
                <li>
                  <AshCharacter
                    slug="tian-ruo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Small, spammable Barrier and a team
                  Heal are more than enough to earn him a spot early on.
                </li>
                <li>
                  <AshCharacter
                    slug="lanlan"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[CC]</strong> - Lanlan possesses strong AoE CCs. What
                  she lacks however is damage and survivability, making her a
                  more relevant reserve unit than fully active. For AUTO play,
                  Lanlan is a strong pick for Defensive play.
                </li>
                <li>
                  <AshCharacter
                    slug="begonia"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[DPS]</strong> - Begonia can be used for a teamwide
                  ATK Speed buff and Block Gauge Breaking should you not be
                  playing Felicio.
                </li>
                <li>
                  <AshCharacter
                    slug="freda"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Freda’s buffs are still extremely
                  relevant to the Wind team, however they are not as crucial as
                  they could be in other synergies.
                </li>
                <li>
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Support]</strong> - Thanks to her versatile kit, Su
                  Xiao can stand in as the Support of the team until you get
                  more options. On top of that, her permanent Crit rate buff
                  synergizes well with the Wind Team.
                </li>
                <li>
                  <AshCharacter
                    slug="shu"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <strong>[Tank]</strong> - Shu brings even more CC to the Team
                  with his frequent AoE Stuns and his huge Gather Ult.
                </li>
              </ul>
              <h6>Memory Traces</h6>
              <ul>
                <li>
                  <AshTrace name="Blood Ruvia - Secret" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile /> (if with
                  Longqing or Tian Ruo) or{' '}
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <p>Alternative/Budget</p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                  (Replaces “Shadows Of The Alley”)
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Corrosion Team</a>
                </li>
                <li>
                  <a href="#section-1">Electrocution Team</a>
                </li>
                <li>
                  <a href="#section-2">Freeze Team</a>
                </li>
                <li>
                  <a href="#section-3">Summoner (Physical) Team</a>
                </li>
                <li>
                  <a href="#section-4">Pure Fire Team</a>
                </li>
                <li>
                  <a href="#section-5">Explosion Team</a>
                </li>
                <li>
                  <a href="#section-6">Wind (Pre-Scarlett) Team</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesTeam;

export const Head: React.FC = () => (
  <Seo
    title="Team Building | Ash Echoes | Prydwen Institute"
    description="A team building guide for Ash Echoes that will help you build strong teams!"
    game="ash"
  />
);
